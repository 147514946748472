import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";


function Footer(){
return(
    <div className='side-pad-mobile' style={{width:"100%", padding:"3.5em 3em", marginTop:"0px",backgroundColor:"#000",}}>
        <center>
                <div className='col-md-7' style={{color:"#e3e3e3"}}>
                    <div className="h6" style={{fontFamily:"Poppins-Regular"}}>Connect with Boluwaji Davids</div>
                    <div style={{marginTop:"12px", }}>Join conversations on Twitter <Link target={"_blank"} to={"https://twitter.com/hashtag/ReclaimingHope"}>#ReclaimingHope</Link> <Link target={"_blank"} to={"https://twitter.com/hashtag/ReclaimingHopeAfrica"}>#ReclaimingHopeAfrica</Link> </div>
                    <br/>
                    <div style={{display:"flex", justifyContent:'center', alignItems:"center"}}>
                        <Link to={"https://twitter.com/boluwajidavids"} target="_blank">
                            <div className="social-box">
                                <FaTwitter size={25} />
                            </div>
                        </Link>
                        <Link to={"https://facebook.com/boluwajidavids"} target="_blank">
                            <div className="social-box">
                                <FaFacebook size={25} />
                            </div>
                        </Link>
                        <Link to={"https://instagram.com/boluwajidavids"} target="_blank">
                            <div className="social-box">
                                <FaInstagram size={25} />
                            </div>
                        </Link>
                        <Link to={"https://youtube.com/@boluwajidavids"} target="_blank">
                            <div className="social-box">
                                <FaYoutube size={25} />
                            </div>
                        </Link>
                    </div>
                    <br/>
                </div>
                <hr/>
                <br/>
                <div style={{color:"#e3e3e3"}}>&copy; ReclaimingHope { ( new Date).getFullYear()} </div>
        </center>
    </div>
)
}
export default Footer;