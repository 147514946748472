import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';
import TestimonialsBox from '../components/testimonials';
import Colors from '../data/colors';

//backgroundImage:"url(/img/bd1.jpg)", backgroundSize: 'cover', backgroundPosition:' center', backgroundRepeat: 'no-repeat'

function HomePage(){
return (
    <div style={{width:"100%", height:"100%"}}>
        <Header />
        <div style={{width:"100%", marginTop:'70px', backgroundColor:"#fff", zIndex:1}}>
            <div className='row no-margin'>
                <div className='col-md-6 no-padding'>
                    <div style={{width:"100%",  }}>
                        <img src="img/bd1.jpg" style={{width:"100%", zIndex:1}} alt="Boluwaji Davids" />
                    </div>
                </div>
                <div className='col-md-6 no-padding'>
                    <div className='title-bag'>
                        <center>
                            <div className="h4" style={{color:Colors.red, fontFamily:"Poppins-Bold"}}>RECLAIMING HOPE:</div>
                            <div style={{fontFamily:"Poppins-Light", fontSize:"2.1em"}}>Time for Africa's Rebirth</div>
                            <br/>
                            <div className='author-tag' style={{fontFamily:"Poppins-Regular", color:"#8e8f92"}}>BOLUWAJI DAVIDS</div>
                            <br/><br/>
                            <Link to={"order"}>
                                <div className='order-button'>ORDER NOW</div>
                            </Link>
                            <br/>
                        </center>
                    </div>
                </div>
            </div>
        </div>

        <div className='side-pad-mobile2' style={{backgroundColor:Colors.bg2, padding:"3.5em 3em", marginTop:"-10px", zIndex:10}}>
           
            <div className='row no-margin'>
                <div className='col-md-5 no-padding'>
                    <center>
                        <img src='img/cover.png' style={{width:"80%"}}  />
                    </center>
                </div>
                <div className='col-md-7 no-padding'>
                    <p style={{fontSize:"22px"}}>
                        <div style={{fontFamily:"Poppins-Medium", color:"#fff", marginTop:"10px", fontSize:"25px"}}>
                            <span style={{fontFamily:"Poppins-Bold"}}>Reclaiming Hope</span> is a riveting read on contemporary Africa, delivering an urgent message on renewing hope and rebuilding the African continent for a prosperous future. It is challenging and inspiring.
                        </div>
                        <br/>
                        <div style={{fontFamily:"Poppins-Regular", color:"#fff", marginTop:"10px",  }}>
                            Reclaiming Hope is a charge to the African to believe in himself and in his own ability to drive change and ultimately transform Africa. As Africans, we often look outwards, hoping that a Messiah will emerge to solve our problems. Reclaiming Hope is a challenge for the African to become his own Messiah and deliver the change we all seek.
                        </div>
                        <br/>
                        <div style={{fontFamily:"Poppins-Regular", color:"#fff", marginTop:"10px",  }}>
                            In this book, Boluwaji takes the reader through an inspiring journey - delving into our root as Africans as the necessary starting point for reclaiming our collective identity, which is crucial to fostering an African Renaissance. He navigates the subject of Unity, and argues that our experience of slavery and colonialism planted seeds of division that must be deliberately uprooted in order for Africa to move forward.
                        </div>

                    </p>
                </div>
            </div>

            <p style={{fontSize:"22px", padding:"10px 0.5em"}}>
                <br/>
                <div style={{fontFamily:"Poppins-Regular", color:"#fff", marginTop:"10px",  }}>
                     Boluwaji reflected on our shared values as Africans and argues that reaffirming those shared values which are foundational to African societies is an inevitable requirement for a prosperous future. On the touchy matter of religion, Boluwaji offers alternate perspectives that encourages the African to question what he accepts as religious, noting that society always evolve, and so must religion. Boluwaji also explored the subject of leadership, reflecting on the nature of leadership that Africa require to evolve as a prosperous continent. He notes further, the need for the African to invest in his mind, arguing that the mind is where the new Africa will be fashioned.
                </div>

                <br/>
                <div style={{fontFamily:"Poppins-Regular", color:"#fff", marginTop:"10px",  }}>
                    Reclaiming Hope ends with a stirring message in the final chapter: change is possible and it is upto citizens to march for it. It provides a complete toolbox for the changemaker to mobilize his community for the march for change. It is a bold call for change - challenging the African to pick up the mantle of leadership and inspire change in his community and nation. That is where the transformation of Africa must begin. There is hope for Africa, and that hope reside in her people.
                </div>

                <br/>
                <div style={{fontFamily:"Poppins-Regular", color:"#fff", marginTop:"10px",  }}>
                    Reclaiming Hope is a must read for anyone who believes in the future of Africa and aspires to lead change in their communities.
                </div>
            </p>
        </div>


        <TestimonialsBox />

        <div className='side-pad-mobile' style={{width:"100%", padding:"3.5em 3em", marginTop:"10px",backgroundColor:"#e3e3e3",}}>
            <center>
                <div className='col-md-7'>
                    <div className="h5" style={{fontFamily:"Poppins-Medium"}}>ORDER</div>
                    <div style={{marginTop:"12px", }}>Door delivery of Reclaiming Hope is available in Nigeria. For bulk orders and orders across Africa, please send a mail to orders@reclaiminghope.africa</div>
                    <br/>
                    <Link to={'order'}>
                        <div className='order-button'>ORDER RECLAIMING HOPE</div>
                    </Link>
                    <br/>
                </div>
            </center>
        </div>

        <Footer />
    </div>
)
}
export default HomePage;