const Colors = {
    bg:'#bababa',
    bgg:"#e5e5e5",
    bg2:"#5b5c5d",
    //bg2:"rgb(142, 143, 146)",
    red:"#df557a",
    bgShade1:"#eff5f7",
   blueBG:'#f7fbfc',
   borderShade1:"#e8f6fb",
   bg1:"#feffff",
   bg1_:"#070707",
   //bg2:"#f4fde8",
   light:"#fff",
   bgLight:"#fff",
   bgLight_:"#243226",
   dark1:"#0d1f22",
   primary0:"#254129",
   primary:"#00a758",
   primary_:"#b8c4aa",
   primary1:"#f1f7b0",
   primary2:"#f4e285",
   primary3:"#d62828",
   black:'#32425b',
   blacked:"#000000",
   gray:"#7b8b83",
   bluegray:"#eaedf2",
   danger:'#fb0707',
}

export default Colors