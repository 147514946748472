
function useValidation() {
   return{
   isNumber:function(val){
        if(!val.match("^[0-9-]+$")) return false;
        return true;
      },
  toLocal:(i)=>{ 
    return i.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",");
  },
   isEmail:function(val){
       const validEmailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;    
       if(val.length < 5 || !validEmailRegexp.test(val.toLowerCase())) return false;
        return true;
      },
   isUrl:function(val){
       const validUrlRegexp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g; 
       return validUrlRegexp.test(val.toLowerCase());  
      },
   hasLength:function(val, len){
        if(val.length ==len) return true;
        return false;
      },      
   hasMinimumLength:function(val, len){
        if(val.length >= len) return true;
        return false;
      },
   isValidPassword: function(val){
       if(!val || val.length < 8) return "Password should have at least 8 characters";
       if (!/\d/.test(val)) {
         return 'Password should have at least one digit';
       }
       if (!/[A-Z]/.test(val)) {
         return 'Password should have at least one Capital Letter';
       }
       if (!/[a-z]/.test(val)) {
         return 'Password should have at least one small Letter';
       }
       if (!/\W/.test(val)) {
         return 'Password should have at least one special character';
       }
       return "valid"
   },
   getMonth:function(n){
     let m
    switch(n){
      case 0 : m = "Jan"; break;
      case 1 : m = "Feb"; break;
      case 2 : m = "Mar"; break;
      case 3 : m = "Apr"; break;
      case 4 : m = "May"; break;
      case 5 : m = "Jun"; break;
      case 6 : m = "Jul"; break;
      case 7 : m = "Aug"; break;
      case 8 : m = "Sep"; break;
      case 9 : m = "Oct"; break;
      case 10 : m = "Nov"; break;
      case 11 : m = "Dec"; break;
    }
    return m;
  },
  getDay:function(n){
    let m
   switch(n){
     case 0 : m = "Sun"; break;
     case 1 : m = "Mon"; break;
     case 2 : m = "Tue"; break;
     case 3 : m = "Wed"; break;
     case 4 : m = "Thu"; break;
     case 5 : m = "Fri"; break;
     case 6 : m = "Sat"; break;
   }
   return m;
 },
    
}
    
}
     export default useValidation;