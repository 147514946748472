import React from 'react';
import { FiInfo } from 'react-icons/fi';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import Select from 'react-select'

import 'react-tooltip/dist/react-tooltip.css'

import Colors from '../data/colors';

//type Size = "small" | "medium" | "big";
//type TipType = "dark" | "light" | "success" | "warning" | "error" | "info"

 

function Selector({
      list, placeholder, errorMessage, value, multi,
      required,hasError, hastip, callback, label, tipMessage, tipType, primaryCol, bg
   }){

let customStyles = {
   control:(provided)=> ({...provided, backgroundColor: bg || Colors.bgShade1,  }),
   placeholder:(provided)=>({...provided, fontSize:"14px"}),
   valueContainer:(provided)=>({...provided, padding:"5px 8px", fontSize:"15px", fontFamily:"Raleway-Medium",}),
   option:(provided)=>({...provided, padding:"10px 8px", fontSize:"14px", fontFamily:"Raleway-Medium", cursor:"pointer"}),
}

function getTheme(theme){
   return {
      borderRadius:5,
      colors:{
         ...theme.colors,
         primary:primaryCol || Colors.primary,
         primary25:Colors.bg,
         danger:Colors.danger,
         neutral0:"#fff",
         neutral20:"#d2e0e5",
         neutral30:Colors.bgShade1+"40",
         neutral50:Colors.gray,
         neutral60:Colors.bgShade1+"70",
         
      }, 
      spacing:{controlHeight:50, baseUnit:1, menuGutter:7}
   }

}
return (
   <div style={{width:'100%', marginBottom:'10px'}}>
      <div style={{width:'100%', marginBottom:'3px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
         <div>
            {required?<span className="required">*</span>:null}
            <span style={{fontSize:'13px', fontWeight:600,}}>{label}</span>
         </div>
         {hastip?
         <div data-tip={tipMessage} style={{width:'24px', height:'24px', borderRadius:'12px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:Colors.gray}}>
            <FiInfo color={Colors.black} />
         </div>
         :null}
      </div>
      <div style={{   width:'100%',  }}>
         <Select 
            placeholder={placeholder}
            options={list}
            value = {value}
            isMulti ={multi? true : false}
            onChange={(val)=>callback(val)}
            menuPlacement="auto"
            style={{fontSize:'0.7em',}} 
            styles = {customStyles}
            theme={getTheme}
          />
      </div>
      {hasError?
      <div>
         <span style={{fontSize:'12px', color:"red", marginTop:'0px'}}>
            {errorMessage}
         </span>
      </div>
      :null}
      <ReactTooltip multiline={true} type = { tipType || "dark" } />
   </div>
)
}
export default Selector;