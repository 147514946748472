import React, { useState} from 'react';
import { FiEye, FiEyeOff, FiInfo } from 'react-icons/fi';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
//const ReactTooltip = dynamic(()=>import('react-tooltip'), {ssr:false});

import Colors from '../data/colors';

function Input({  
      size, placeholder, value, callback,  color,
      password, bg, Icon, type, label, required, disabled,
      hasError, errorMessage, hastip, tipMessage, tipType, visibleFeedback,
   }){
const [hide, setHide] = useState(false);
const [typ, setType] = useState(type ? type :"text");
let m = 0;
if(password) {m +=20;}

return(
   <div style={{width:'100%', marginBottom:'10px', marginTop:"5px"}}>
      <div style={{width:'100%', marginBottom:'3px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
         <div>
            {required?<span className="required">*</span>:null}
            <span style={{fontSize:'13px', fontWeight:600,}}>{label}</span>
         </div>
         {hastip?
         <div data-tip={tipMessage} style={{width:'24px', height:'24px', borderRadius:'12px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:Colors.gray}}>
            <FiInfo color={Colors.primary} />
         </div>
         :null}
      </div>
      <div className={'input'} style={{
         backgroundColor:  bg || Colors.bgLight,
         padding:!size ? "3px" : size=="small"? "1px" : size=="medium" ? "3px" : "5px",
         paddingRight:"5px",
         borderWidth:'1px',
         borderStyle:'solid',
         borderColor: hasError ? "red": Colors.borderShade1,
         display:'flex',
         flexDirection:'row',
         alignItems:'center',
      }}>
         {Icon ? <Icon /> : null}
         <input className="input-width"
            placeholder={placeholder}
            type={typ}
            value={value}
            onChange={(e)=>callback? callback(e.target.value):null}
            disabled={disabled ? true : false}
            style={{
               border:'none', color: color || Colors.black, backgroundColor:  bg || Colors.light,
               padding:!size ? "10px" : size=="small"? "8px" : size=="medium" ? "10px" : "13px",
               fontSize:!size ? "16px" : size=="small"? "14px" : size=="medium" ? "16px" : "18px",
               minWidth: password ? '96%' : '100%',
               }} />
         {password ?               
         <span onClick={()=>{setHide(!hide); if(visibleFeedback){ visibleFeedback(hide)} setType(hide? "password" :"text")} } style={{color: color || Colors.black}}>
            {hide ? 
               <FiEye size={14} />  :<FiEyeOff size={14}  />
            }
         </span>
         :null}
          
      </div>
      {hasError?
      <div>
         <span style={{fontSize:'12px', color:"red", marginTop:'0px'}}>
            {errorMessage}
         </span>
      </div>
      :null}
      <ReactTooltip multiline={true} type = { tipType || "dark" } />
   </div>
)
}
export default Input;