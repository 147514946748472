import { useToasts,  AppearanceTypes } from 'react-toast-notifications';


function useAlert(){
   let { addToast } = useToasts();
   return {
      toast: (message, type)=>{
         addToast(message, {appearance:type}); 
         },
   }
}

export default useAlert;
