import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';




function NotFound404(){
return(
    <div>
        <Header />
        <div className='side-pad-mobile' style={{width:"100%", padding:"3.5em 3em", marginTop:"10px",}}>
            <br />
            <br/>
            <div style={{height:"35vh", display:"flex", justifyContent:'center', alignItems:"center"}}>
                <center>
                    <div>This link may be broken. Visit <Link to={"/"}>Home Page</Link></div>
                </center>
            </div>
            <br/>
        </div>

        <Footer />
    </div>
)
}
export default NotFound404;