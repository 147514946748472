import { useLayoutEffect } from 'react'
import { createBrowserRouter, useLocation} from "react-router-dom";
import HomePage from './pages/home';
import LaunchPage from './pages/launch';
import PreorderPage from './pages/preorder';
import NotFound404 from './pages/404';
import OrderPage from './pages/order';


const App = createBrowserRouter([
  {
    path:"*",
    element:<NotFound404 />
  },
  {
    path:'/',
    element: <HomePage />
  },
  {
    path:"/launch",
    element:<LaunchPage />
  },
  {
    path:"/preorder",
    element:<PreorderPage />
  },
  {
    path:"/order",
    element:<OrderPage />
  },
]);



export default App;
