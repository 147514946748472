import React from 'react';
import Colors from '../data/colors';

let testimonies = require('./testimonies.json');

function TestimonialsBox(){
return(
    <div className='side-pad-mobile' style={{width:"100%", padding:"3.5em 3em", marginTop:"10px",}}>
        <div>
            <br/>
            <div className='author-tag' style={{fontFamily:"Poppins-Regular", color:"#8e8f92", textAlign:'center'}}>READERS REVIEW</div>
            <br/><br/>
        </div>
        <div style={{display:"flex", flexWrap:"wrap", width:"100%"}}>
        {
            testimonies.map((t)=> !t.ignore &&
               <div className='col-md-3' style={{margin:"15px 0px"}}>
                    <div style={{backgroundColor:"#e3e3e3", padding:"15px", borderRadius:"5px"}}>
                        <div style={{display:'flex', alignItems:'center', }}>
                            <div>
                                {t.image && <img src={t.image} style={{width:"50px", height:"50px", borderRadius:"25px", marginRight:"8px"}} />}
                            </div>
                            <div>
                                <div style={{fontSize:"14px", fontFamily:"Poppins-Bold", color:Colors.red }}>{t.author}</div>
                                <div style={{fontSize:"12px",  }}>{t.title}</div>
                            </div>
                        </div>
                        <div style={{fontSize:"14px", marginTop:"15px"}}>
                            {t.review}
                        </div>
                    </div>
                </div>
            )
        }
        </div>
    </div>
)
}
export default TestimonialsBox