import React, {useRef, useState, useEffect} from "react";

import { Link } from "react-router-dom";
import { OverlayPanel } from 'primereact/overlaypanel';
import Colors from "../data/colors";
import { FaPowerOff } from "react-icons/fa";



function Header({bg, title}){
let [active, setActive] = useState(null)



return(
    <div className="side-padding bg-mobile" style={{width:"100%",zIndex:2, position:"fixed", top:"0px", padding:"15px 2em", backgroundColor:'#fff',  }}>
        <div style={{width:"100%", display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
            <div style={{zIndex:100, width:"100%",}}>
                <Link to={"/"}>
                    <div className="h5 bold" style={{fontFamily:"Poppins-Regular", textAlign:'center', width:"100%", marginTop:"10px", }}>
                        {title || "RECLAIMING HOPE"}
                    </div>
                </Link>
            </div>
            
        </div>

         
    </div>
)
}
export default Header;