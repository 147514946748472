
import useAlert from './toast';

function useApi(){
   let apiBase = process.env.REACT_APP_BASE_URL;
   let alert = useAlert();

   return {
   
   post:async(url, payload)=>{ 
      
      let request= await fetch(apiBase + url,
         { method:'POST', 
           body: JSON.stringify(payload),
           headers:{"Content-Type":"application/json", } }
           )
         .then(response=>response.json())
         .catch((e)=> e.message); //console.log(request);
      if(!request || !request.status){
         return {status:"error", message: typeof(request)=="string" ? request : JSON.stringify(request)}
      }
      //refresh();
      return request;
   },
    
}
}
export default useApi;