import React from "react";
import { createPortal } from 'react-dom';


function FullModal({children, open}){
return(
   <div>
        {open && createPortal(
            <div style={{width:"100%", minHeight:"100vh", height:"100%", position:'fixed', top:"0px", bottom:"0px", right:'0px', zIndex:1000,
                backgroundColor:"rgb(0, 0, 0, 0.5)", display:"flex", alignItems:"center", justifyContent:"center"}}>
                {children}
            </div>
            ,
            document.body)
        }
   </div>
)
}
export default FullModal;