import { Divider } from 'primereact/divider';
import React, {useState} from 'react';
import { FaCopy } from 'react-icons/fa';
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import Input from '../components/input';
import FullModal from '../components/modal';
import Selector from '../components/select';
import TextArea from '../components/textarea';
import Colors from "../data/colors";
import useApi from '../utils/api';
import useAlert from '../utils/toast';
import useValidation from '../utils/validate';


function PreorderPage(){
let [open, setOpen] = useState(false)
let [busy, setBusy] = useState(false);
let [bank, setBank] = useState(null)
let [fname, setFname] = useState({})
let [lname, setLname] = useState({})
let [email, setEmail] = useState({})
let [phone, setPhone] = useState({})
let [state, setState] = useState({v:null})
let [city, setCity] = useState({})
let [address, setAddress] = useState({v:''})
let [qty, setQty] = useState({qty:1});

let api = useApi();
let alert = useAlert();
let validate = useValidation();

let states = require("../data/states.json"); 
let states_ = states.map((s)=>{
    return {value:s?.name?.toLocaleLowerCase()?.replaceAll(" ", "-"), label:s.name}
});


let qty_list = [{label:1, value:1}, {label:2, value:2}, {label:3, value:3},{label:4, value:4},{label:5, value:5},{label:6, value:6},{label:7, value:7},{label:8, value:8}, {label:9, value:9}]


async function process(){
    let err = false;
    if(!fname.v || fname.v.length < 2 ){
       fname.e=true; fname.m="Provide a valid firstname"; setFname(JSON.parse(JSON.stringify(fname))); err=true;  }
    else{ fname.e=false; err=false; setFname(JSON.parse(JSON.stringify(fname))); }
    if(!lname.v || lname.v.length < 2 ){
       lname.e=true; lname.m="Provide a valid lastname"; setLname(JSON.parse(JSON.stringify(lname))); err=true;  }
    else{ lname.e=false; err=false; setLname(JSON.parse(JSON.stringify(lname))); }
    
    if(!email.v || email.v.length < 5 || !validate.isEmail(email.v)){
       email.e=true; email.m="Provide a valid email address"; setEmail(JSON.parse(JSON.stringify(email))); err=true;
    }
    else{ email.e=false; err=false; setEmail(JSON.parse(JSON.stringify(email))); }
    if(!phone.v || phone.v.length < 7 || !validate.isNumber(phone.v)){
       phone.e=true; phone.m="Provide a valid phone number"; setPhone(JSON.parse(JSON.stringify(phone))); err=true;   }
    else{ phone.e=false; err=false; setPhone(JSON.parse(JSON.stringify(phone))); }
    if(!city.v || city.v.length < 2 ){
       city.e=true; city.m="Provide a valid phone number"; setCity(JSON.parse(JSON.stringify(city))); err=true; }
    else{ city.e=false; err=false; setCity(JSON.parse(JSON.stringify(city))); }

    if(!qty.v || qty.v==null){
        qty.e=true; qty.m="Select no of copies to preorder"; setQty(JSON.parse(JSON.stringify(qty))); err=true;   }
     else{ qty.e=false; err=false; setQty(JSON.parse(JSON.stringify(qty))); }
     if(!state.v || state.v==null){
        state.e=true; state.m="Select delivery state"; setState(JSON.parse(JSON.stringify(state))); err=true;   }
     else{ state.e=false; err=false; setState(JSON.parse(JSON.stringify(state))); }

     if(!address.v ||address.v.length < 10 ){
        address.e=true; address.m="Provide a valid delivery Address"; setAddress(JSON.parse(JSON.stringify(address))); err=true; }
     else{ address.e=false; err=false; setAddress(JSON.parse(JSON.stringify(address))); }
     
    if(err){
       alert.toast("Work in progress. Kindly check back later", 'info'); return;
    }
    setBusy(true);
    let payload = {fname:fname.v, type:'preorder', qty:qty.v.value, lname:lname.v, email:email.v, phone:phone.v, state:state.v.value, city:city.v, address:address.v}
    let resp = await api.post("order", payload); //console.log(resp);
    setBusy(false);
    alert.toast(resp.message);
    if(resp.status=="ok"){
        setOpen(true); setBank(resp.bank);
        email.v=''; fname.v=''; lname.v=''; city.v=''; address.v='';
        setAddress(JSON.parse(JSON.stringify(address)));
        setFname(JSON.parse(JSON.stringify(fname)));
        setLname(JSON.parse(JSON.stringify(lname)));
        setEmail(JSON.parse(JSON.stringify(email)));
        setCity(JSON.parse(JSON.stringify(city)));
    }
    //Router.push("/auth/verify-email")
    //make network request here
}

function copyAccount(){
    var textarea = document.createElement("textarea");
    textarea.textContent = 'text';
    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
        let c = document.execCommand("copy"); 
        if(c){
            alert.toast("Account number copied!", 'success')
        } // Security exception may be thrown by some browsers.
    } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
    } finally {
        document.body.removeChild(textarea);
    }
}


return(
<div>
    <Header title ="PREORDER RECLAIMING HOPE" />

    <div style={{width:"100%", marginTop:'70px', backgroundColor:"#fff", zIndex:1}}>
            <div className='row no-margin'>
                <div className='col-md-5 no-padding'>
                    <div className='side-pad-mobile' style={{width:"100%", padding:"3.5em 3em" }}>
                        <img src="img/mock1.png" style={{width:"100%", borderRadius:"20px"}} alt="Boluwaji Davids" />
                    </div>
                </div>
                <div className='col-md-6 no-padding'>
                    <div className='title-bag' style={{justifyContent:"flex-start"}}>
                        <div>
                            <div className="h4" style={{color:Colors.red, fontFamily:"Poppins-Bold"}}> PREORDER RECLAIMING HOPE</div>
                            <div style={{fontFamily:"Poppins-Light", fontSize:"2em"}}>Preorder Reclaiming Hope before its public release on December 10th and get a 10% discount.</div>
                            <br/>
                            <div style={{fontFamily:"Poppins-Regular", fontSize:"1.5em", color:Colors.red}}>Get 10% off</div>
                            <div className='author-tag' style={{fontFamily:"Poppins-Medium", color:"#8e8f92"}}>Get a copy @ N4,500</div>
                            <br/><br/>
                            
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    

    <div id="launch" className='side-pad-mobile' style={{width:"100%", padding:"3.5em 3em", marginTop:"10px",backgroundColor:"#e3e3e3",}}>
        <center>
            <div className='col-md-8 no-padding-on-mobile '>
                <div className="h5" style={{fontFamily:"Poppins-Medium"}}>PRE ORDER</div>
                <br />

                <div className='row no-margin'>
                    <div className="col-md-6">
                        <div>
                            <Input
                                placeholder={"First name"} value={fname.v} 
                                callback={(e)=>{fname.v=e; setFname(JSON.parse(JSON.stringify(fname)))}}
                                errorMessage={fname.m} hasError={fname.e}
                             />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <Input
                                placeholder={"Last name"} value={lname.v} 
                                callback={(e)=>{lname.v=e; setLname(JSON.parse(JSON.stringify(lname)))}}
                                errorMessage={lname.m} hasError={lname.e}
                             />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <Input
                                placeholder={"Phone"} value={phone.v} type="number"
                                callback={(e)=>{phone.v=e; setPhone(JSON.parse(JSON.stringify(phone)))}}
                                errorMessage={phone.m} hasError={phone.e}
                             />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <Input
                                placeholder={"Email"} value={email.v} 
                                callback={(e)=>{email.v=e; setEmail(JSON.parse(JSON.stringify(email)))}}
                                errorMessage={email.m} hasError={email.e}
                             />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <Selector
                                placeholder={"No. of Copies to preorder"} value={qty.v} 
                                callback={(e)=>{qty.v=e; setQty(JSON.parse(JSON.stringify(qty)))}}
                                errorMessage={qty.m} hasError={qty.e}
                                list = {qty_list} primaryCol={Colors.red}
                             />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <Selector
                                placeholder={"Select State"} value={state.v} list={states_}
                                callback={(e)=>{state.v=e; setState(JSON.parse(JSON.stringify(state)))}}
                                errorMessage={state.m} hasError={state.e} primaryCol={Colors.red}
                             />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <Input
                                placeholder={"City or Town"} value={city.v} 
                                callback={(e)=>{city.v=e; setCity(JSON.parse(JSON.stringify(city)))}}
                                errorMessage={city.m} hasError={city.e}
                             />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <TextArea
                                placeholder={"Detailed Delivery Address"} value={address.v} 
                                callback={(e)=>{address.v=e; setAddress(JSON.parse(JSON.stringify(address)))}}
                                errorMessage={address.m} hasError={address.e}
                             />
                        </div>
                    </div>
                    
                     
                </div>
                    <div style={{marginTop:"12px", }}>Payment instruction will be sent to you via email. Delivery for preorders will start from December 11 after the official release of Reclaiming Home. For bulk orders, please send a mail to orders@reclaiminghope.africa</div>
                    <br/>
                    <div className='col-md-5'>
                        <div onClick={process} className='order-button'>PREORDER</div>
                    </div>
                    <br/>
            </div>
        </center>
    </div>


    <Footer/>


    <FullModal open={busy}>
        <div style={{width:"250px", padding:"10px 15px", backgroundColor:"#fff", borderRadius:"10px", zIndex:100}}>
            <div style={{display:"flex", flexDirection:"row", alignItems:"center", width:"100%", padding:"10px 10px"}}>
                <div style={{width:"50px"}}>
                    <div className="show-loading" ></div>
                </div>
                <div style={{width:"200px", fontSize:"13px", color:"#000", }}>
                    Please wait a moment...
                </div>
            </div>
        </div>
    </FullModal>

    <FullModal open={open}>
        <div className="col-md-8" style={{ padding:"10px 15px", backgroundColor:"#fff", borderRadius:"10px", zIndex:100}}>
            <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%", padding:"18px 10px"}}>
                <div>
                    <div className="h5" style={{fontFamily:"Poppins-Bold", textAlign:"center"}}> Thank you for your order</div>
                    <div style={{fontFamily:"Poppins-Regular", fontSize:"0.9em", textAlign:'center', marginTop:"10px"}}> Kindly find details of your order below. Your order will be processed once payment is received in the account details provided below. </div>
                    <br/>

                    <Divider align="center">
                        <div style={{backgroundColor:Colors.red, color:"#fff", width:"20px", height:"20px", borderRadius:"50%"}} className="p-tag"></div>
                    </Divider>

                    <br/>
                    <div style={{fontSize:"16px", textAlign:"center", fontFamily:"Poppins-Bold"}}>
                        Order Details
                    </div>

                <center> <br/>
                    <div className="col-md-7">
                        <table style={{border:"1px solid gray", borderRadius:'10px'}}>
                            <tr style={{borderBottom:"1px solid gray", }}>
                                <td style={{padding:"5px 10px", width:"40%", borderRight:"1px solid gray",  }}>Quantity:</td>
                                <td style={{padding:"5px 10px", width:"60%", fontFamily:"Poppins-Bold" }}>{bank?.qty} </td>
                            </tr>
                            <tr style={{borderBottom:"1px solid gray", }}>
                                <td style={{padding:"5px 10px", width:"40%", borderRight:"1px solid gray",  }}>Price</td>
                                <td style={{padding:"5px 10px", width:"60%", fontFamily:"Poppins-Bold" }}> {bank?.price}</td>
                            </tr>
                            <tr style={{borderBottom:"1px solid gray", }}>
                                <td style={{padding:"5px 10px", width:"40%", borderRight:"1px solid gray",  }}>Delivery</td>
                                <td style={{padding:"5px 10px", width:"60%", fontFamily:"Poppins-Bold" }}> {bank?.delivery}</td>
                            </tr>
                            <tr style={{borderBottom:"1px solid gray", }}>
                                <td style={{padding:"5px 10px", width:"40%", borderRight:"1px solid gray",  }}>Total</td>
                                <td style={{padding:"5px 10px", width:"60%", fontFamily:"Poppins-Bold" }}>NGN{bank?.total}</td>
                            </tr>
                            <tr style={{borderBottom:"1px solid gray", }}>
                                <td style={{padding:"5px 10px", width:"40%", borderRight:"1px solid gray",  }}>Account Number:</td>
                                <td style={{padding:"5px 10px", width:"60%", fontFamily:"Poppins-Bold" }}>{bank?.account_number}  <FaCopy onClick={copyAccount} style={{cursor:"pointer"}} color={Colors.red} /></td>
                            </tr>
                            <tr style={{borderBottom:"1px solid gray", }}>
                                <td style={{padding:"5px 10px", width:"40%", borderRight:"1px solid gray",  }}>Account Name</td>
                                <td style={{padding:"5px 10px", width:"60%", fontFamily:"Poppins-Bold" }}> {bank?.account_name}</td>
                            </tr>
                            <tr>
                                <td style={{padding:"5px 10px", width:"40%", borderRight:"1px solid gray",  }}>Bank</td>
                                <td style={{padding:"5px 10px", width:"60%", fontFamily:"Poppins-Bold" }}>{bank?.bank_name}</td>
                            </tr>
                        </table>
                    </div>
                </center>
                     
                    <br/>
                    <center>
                        <div onClick={()=>setOpen(false)} style={{backgroundColor:Colors.red, cursor:"pointer", color:"#fff", textAlign:'center', width:"65px", padding:"10px 20px", borderRadius:"10%"}}>OK</div>
                    </center>
                    <div style={{marginTop:"10px", marginBottom:"20px"}}>
                        
                    </div>
                </div>
            </div>
        </div>
    </FullModal>
</div>
)
}
export default PreorderPage;